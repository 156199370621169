/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
html,
body,
#root {
  height: 100%;
}
.colorWeak {
  -webkit-filter: invert(80%);
          filter: invert(80%);
}
.ant-layout {
  min-height: 100vh;
}
canvas {
  display: block;
}
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul,
ol {
  list-style: none;
}
@media (max-width: 480px) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > th,
  .ant-table-thead > tr > td,
  .ant-table-tbody > tr > td {
    white-space: pre;
  }
  .ant-table-thead > tr > th > span,
  .ant-table-tbody > tr > th > span,
  .ant-table-thead > tr > td > span,
  .ant-table-tbody > tr > td > span {
    display: block;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .ant-design-pro > .ant-layout {
    min-height: 100vh;
  }
}
.import-woo-modal {
  display: flex;
  flex-direction: column;
  width: 514px !important;
  height: 335px !important;
}
.import-woo-modal .ant-modal-content {
  width: 100%;
  height: 100%;
}
.import-woo-modal .ant-row-center {
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.import-woo-modal .ant-row-center h3 {
  font-size: 21px;
  font-weight: 700;
}
.import-woo-modal .ant-row-center p {
  margin: 25px 0px;
  font-size: 18px;
}
.import-woo-modal .ant-row-center .ant-select {
  margin-bottom: 35px;
}
.import-woo-modal .ant-row-center .ant-select-selector {
  width: 75px;
}
.import-woo-modal .ant-row-center button {
  margin-bottom: 30px;
}
.import-woo-modal .ant-row-center button span {
  font-size: 13px;
}
@media (max-width: 600px) {
  .filters-container .ant-card-body {
    padding-bottom: 180px !important;
  }
}
.filters-select {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.filters-select .ant-select-single .ant-select-selector .ant-select-selection-item,
.filters-select .ant-picker-input input {
  color: #1864FF !important;
}
@media (max-width: 1200px) {
  .filters-select {
    flex-direction: column-reverse;
    margin-top: 24px;
  }
  .filters-select .filter-control-buttons {
    margin-top: 0 !important;
  }
}
@media (max-width: 600px) {
  .filters-select .filters-input {
    flex-direction: column;
    align-items: start !important;
    width: 230px;
  }
  .filters-select .filters-input .container-select-input,
  .filters-select .filters-input .select-input {
    width: 100% !important;
  }
}
.suppliers-username-col .ant-list-item-meta,
.sellers-username-col .ant-list-item-meta {
  align-items: center;
}
.suppliers-username-col .ant-list-item-meta-title,
.sellers-username-col .ant-list-item-meta-title {
  padding-top: 3px;
}
@media (max-width: 991px) {
  .suppliers-username-col,
  .sellers-username-col {
    flex-direction: column;
  }
  .suppliers-username-col .ant-list-item-meta,
  .sellers-username-col .ant-list-item-meta {
    align-items: flex-start;
    flex-direction: column;
  }
}
.suppliers-username-col .ant-list-item-meta-content,
.sellers-username-col .ant-list-item-meta-content {
  width: 100%;
}
.action-buttons button {
  width: 80px;
}
.order-action-buttons button {
  width: 150px;
}
@media (max-width: 1568px) {
  .btn-review,
  .btn-view-order {
    margin-bottom: 8px;
  }
}
@media (max-width: 860px) {
  .header-suppliers .ant-card-head-wrapper,
  .header-sellers .ant-card-head-wrapper,
  .orders-header .ant-card-head-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .header-suppliers .ant-card-head-wrapper .ant-card-extra,
  .header-sellers .ant-card-head-wrapper .ant-card-extra,
  .orders-header .ant-card-head-wrapper .ant-card-extra {
    margin-left: inherit;
    padding-top: 0;
  }
}
@media (max-width: 640px) {
  .header-suppliers .ant-card-head-wrapper .ant-input-search,
  .header-sellers .ant-card-head-wrapper .ant-input-search,
  .orders-header .ant-card-head-wrapper .ant-input-search {
    margin-left: 0;
    margin-top: 16px;
  }
  .header-suppliers .ant-card-head-wrapper .ant-radio-button-wrapper,
  .header-sellers .ant-card-head-wrapper .ant-radio-button-wrapper,
  .orders-header .ant-card-head-wrapper .ant-radio-button-wrapper {
    padding: 0 10px;
  }
}
@media (max-width: 860px) {
  .header-sellers {
    height: 91px;
  }
  .header-sellers .ant-input-search {
    margin-left: 0 !important;
    margin-top: 0 !important;
  }
}
